import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router'
import ReactRouterPropTypes from 'react-router-prop-types'
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react'
import { observable, action, makeObservable } from 'mobx'
import { isNil, path } from 'ramda'
import ReCAPTCHA from 'react-google-recaptcha'
import { Helmet } from 'react-helmet'
import Modal from 'react-responsive-modal'

import { html2jsx } from 'Lib/parser'
import Link from 'Components/link'
import { login, trackLogin } from 'Api/endpoints/auth'
import Spinner from 'Components/spinner'
import BaseLayout from 'Containers/Sites/Base/Layouts/base'

const recaptchaRef = React.createRef()

const triggerReports = ({
  snaid, email, firstName, lastName
}) => {
  // For BSS to snowflake tracking
  if (window.bsspx) {
    try {
      window.bsspx.sendData({
        snaid: snaid,
        email,
        name: `${firstName} ${lastName}`,
        first_name: firstName,
        last_name: lastName
      })
      console.log('BSS tracking sent')
    } catch (e) {
      console.warn('BSS tracking Error')
      console.log(e)
    }
  } else {
    console.log('BSS tracking missing')
  }
}

@inject('store')
@observer
class Login extends Component {
  @observable BaseLayout = null;

  @observable username = '';

  @observable password = '';

  @observable rememberCheck = true;

  @observable errorMessage = 'Login Failed';

  @observable showErrorMessage = false;

  @observable isLoggingIn = false;

  @observable recaptchaValue = '';

  @observable modalIsOpen = false;

  @observable showMigrationModal = false;  // Initialize as true to show immediately

  @action.bound
  toggleMigrationModal() {
    this.showMigrationModal = !this.showMigrationModal;
  }

  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    history: ReactRouterPropTypes.history.isRequired
  };

  constructor(props) {
    super(props)
    makeObservable(this)
    // Check URL for newparadigm and show modal as popup
    const urlParts = /^(?:\w+:\/\/)?([^/]+)(.*)$/.exec(window.location.href);
    // console.log(urlParts[1].indexOf('newparadigmresearch') !== -1); // Log the URL parts to the console
    if (urlParts && urlParts[1].indexOf('newparadigmresearch') !== -1) {
      // setTimeout(() => {
      this.showMigrationModal = true
      // }, 1000) // Show popup after 1 second
    }
  }




  @action.bound
  handleUpdateRecaptchaValue(e) {
    this.recaptchaValue = e
  }

  @action.bound
  handleUpdateUsername(e) {
    this.username = e.target.value
    // this.showErrorMessage = false;
  }

  @action.bound
  handleUpdatePassword(e) {
    this.password = e.target.value
    // this.showErrorMessage = false;
  }

  @action.bound
  toggleRememberCheck() {
    this.rememberCheck = !this.rememberCheck
  }

  @action.bound
  async handleDoLogin(e) {
    this.isLoggingIn = true
    e.preventDefault()

    const { username, password } = this
    const { sitecode, brandId } = this.props.store.site

    const { error, value = {} } = await login(username, password, sitecode, this.recaptchaValue)
    const { token } = value

    if (isNil(error)) {
      await this.props.store.user.afterLogin(value)
      this.props.store.user.setUsernameDuringLogin(username)

      if (this.props.store.user.decodedToken) {
        const {
          customerNumber, customerEmail, customerFirstName, customerLastName
        } = this.props.store.user.decodedToken

        if (window.app_env === 'live') {
          window.dataLayer.push({
            event: 'login',
            snaid: customerNumber,
            brandid: brandId,
            device: 'browser'
          })
        }

        trackLogin(sitecode, token)

        triggerReports({
          snaid: customerNumber,
          email: customerEmail,
          firstName: customerFirstName,
          lastName: customerLastName
        })
      } else {
        console.log('No decode token')
      }

      this.redirectMember()
    } else {
      try {
        const err = JSON.parse(error)
        const message = path(['response', 'data', 'message'], err)

        if (message === 'legacy-only') {
          this.toggleModal()
        } else {
          this.errorMessage = !message ? 'Login Failed' : message
        }
      } catch (err) {
        console.log(err)
      }

      this.recaptchaValue = ''
      recaptchaRef.current.reset()
      this.showErrorMessage = true
      this.username = ''
      this.password = ''
    }

    this.isLoggingIn = false
  }

  @action.bound
  redirectMember() {
    const location = this.props.store.ui.destinationAfterLogin || '/my-subscriptions'
    this.props.history.push(location)
  }

  @action.bound
  toggleModal() {
    this.modalIsOpen = !this.modalIsOpen
  }

  render() {
    const { captchaKey } = this.props.store.site
    const { shouldDisplayLoginMsg, isLoggedIn } = this.props.store.ui
    const {
      siteDetails: {
        messages: { loginMsg },
        phone, mail
      }
    } = this.props.store.site
    const { tokenExistsButIsExpired, logout } = this.props.store.user

    if (tokenExistsButIsExpired) {
      logout()
    }

    if (isLoggedIn) {
      return <Redirect to='/my-subscriptions' />
    }

    return (
      <BaseLayout>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <div className='container' id='__page_login'>
          {shouldDisplayLoginMsg && (
            <aside className='row loginMsgContainer'>
              <div className='col-12 loginMsgWrapper'>
                {html2jsx(loginMsg.content)}
              </div>
            </aside>
          )}
          <div className='row'>
            <div className='col-md-12 actions'>
              <section>
                <h4>Subscriber Login</h4>

                <form onSubmit={this.handleDoLogin}>
                  <div className='form-group'>
                    <input
                      type='text'
                      value={this.username}
                      onChange={this.handleUpdateUsername}
                      placeholder='Email/Username'
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='password'
                      value={this.password}
                      onChange={this.handleUpdatePassword}
                      placeholder='Password'
                    />
                  </div>
                  <div className='form-group recaptchaWrapper'>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={captchaKey}
                      onChange={this.handleUpdateRecaptchaValue}
                    />
                  </div>
                  {!this.isLoggingIn && (
                    <button type='submit' className='btn'>
                      Log In
                    </button>
                  )}
                  {this.isLoggingIn && (
                    <button type='submit' className='btn' disabled>
                      <Spinner />
                    </button>
                  )}
                  {this.showErrorMessage && (
                    <div className='invalid-feedback'>{this.errorMessage}</div>
                  )}
                  <div className='row lastLine'>
                    <div className='col-12 passReminder'>
                      <Link to='/reset'>Forgot username or password?</Link>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>

          <div className='row smallLetters'>
            <p>
              You must have a subscription to log in. If you need assistance,
              contact customer service at{' '} <a href={`mailto:${mail.link}`}> {mail.services} </a>{' '}
              or call{' '} <a href={phone.link}>{phone.value}</a>.
            </p>
          </div>
        </div>

        <Modal
          open={this.modalIsOpen}
          onClose={() => { this.modalIsOpen = !this.modalIsOpen }}
          classNames={{
            overlay: 'r-modal-login-overlay',
            modal: 'r-modal-login-modal',
            closeButton: 'r-modal-login-close'
          }}
        >
          <div>
            <h4>A Legacy product is required to access this site. Please access your subscription from one of the appropriate sites below:</h4>
            <ul>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://members.palmbeachgroup.com/login/'>Palm Beach Group</a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://members.bonnerandpartners.com/login/'>Bonner and Partners</a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href='https://members.jeffclarktrader.com/login/'>Jeff Clark Trader</a>
              </li>
            </ul>
          </div>
        </Modal>


        {/* Migration Modal */}
        <Modal
          open={this.showMigrationModal}
          onClose={() => { this.showMigrationModal = false }}
          center
          classNames={{
            overlay: 'r-modal-login-overlay newparadigmresearch_css_overlay',
            modal: 'r-modal-login-modal newparadigmresearch_css_model',
            closeButton: 'r-modal-login-close newparadigmresearch_css_close'
          }}
        >
          <div>
            <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '4px' }}>
              We're Moving!
            </h2>
            <h3 style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '16px' }}>
              (The Good News Is... You're Coming with Us)
            </h3>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <p>Dear Reader,</p>

              <p>
                <em>The Map</em> is getting a new name and a new home… along with a few other changes.
              </p>

              <p>
                Our core mission remains the same and you'll have full access.
              </p>

              <p>
                I'll keep you updated on our existing portfolio, and of course, still bring you the absolute best opportunities I can find. And as a paid subscriber, you don't have to do anything to continue to get access to our upcoming opportunities.
              </p>

              <p>
                The biggest difference is that you'll learn about them in a whole new venue.
              </p>

              <p>
                <em>The Map</em>'s work will continue through <strong>Paradigm Press.</strong>
              </p>

              <p>
                And as you'll learn over the coming weeks and months, this change will provide a lot of new benefits that I think you'll love.
              </p>

              <p>
                We've engineered this transition with as little change as possible on your end.
              </p>

              <p>
                Updates and videos from me will come from a new email address. And of course, we'll post them on a new website.
              </p>

              <p>
                The site will have the full <em>The Map</em> archive, giving you complete online access to all new and existing content. The only difference is that it won't be on the New Paradigm website any more.
              </p>

              <p>
                Check your email for a message from Tuesday, Nov. 12. This email includes your instruction on gaining access to all my articles on the <strong>Paradigm Press</strong> website.
              </p>

              
            <p>If you have questions about the change, you can contact our friends at{' '}
                <a href="https://my.paradigmpressgroup.com/account/help/general" target="_blank" rel="noopener noreferrer"
                  style={{ color: '#295fb9' }}
                >
                  <strong>Paradigm Press</strong>
                </a>{' '}
                by messaging them here.</p>
              

              <p style={{ marginTop: '24px', fontStyle: 'italic' }}>
                Mason Sexton<br />
                Editor, The Map
              </p>
            </div>
          </div>
        </Modal>
      </BaseLayout>
    )
  }
}

export default withRouter(Login)
